import React, { ReactNode, ReactElement } from "react"
import LayoutWrapper from "./LayoutWrapper"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import SEO from "./SEO"

interface Props {
  children: ReactNode
  pageContext: any
}

function HomepageLayout({ children, pageContext }: Props): ReactElement {
  const { title, quote, author, introduction } = pageContext.frontmatter

  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "homepage.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <LayoutWrapper>
      <SEO title={title} />
      <section className="mt-8 lg:flex">
        <div className="flex-col lg:flex lg:w-1/2">
          <h1 className="pr-4 font-serif text-2xl font-bold leading-tight text-blue-800 md:text-4xl">
            {title}
          </h1>
          <blockquote className="px-10 py-8 font-serif text-lg italic leading-snug text-blue-800 md:text-2xl">
            “{quote}”
            <cite className="block text-base not-italic text-right md:text-lg">
              —{author}
            </cite>
          </blockquote>
        </div>
        <div className="items-center justify-end hidden w-1/2 lg:flex">
          <Img
            fluid={data.background.childImageSharp.fluid}
            className="object-contain w-full border border-blue-200 rounded-lg"
            style={{
              height: 400,
              width: 550,
            }}
          />
        </div>
      </section>

      <div className="mt-10 md:flex">
        <aside>
          <p className="text-lg font-thin leading-snug text-blue-900 md:text-xl">
            {introduction}
          </p>
        </aside>
        <article className="md:pl-10">{children}</article>
      </div>
    </LayoutWrapper>
  )
}

export default HomepageLayout
