import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/HomepageLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Unsere Welt befindet sich in einer Phase tiefgreifender Veränderungen. Wichtige geistige Impulse empfängt die Menschheit in solchen Zeiten stets von der Religion. Religionsstifter wie Abraham, Zoroaster, Moses, Buddha, Krishna, Christus und Mohammed haben jeweils entscheidend zur ethischen und kulturellen Entwicklung der Menschheit beigetragen.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.bahai.de/woran-bahai-glauben/bahaullah-und-sein-anspruch/bahaullah/"
      }}>{`Bahá’u’lláh`}</a>{` lehrt, dass die `}<a parentName="p" {...{
        "href": "https://www.bahai.de/woran-bahai-glauben/gott-und-der-mensch/fortschreitende-offenbarung/"
      }}>{`Religionen der Welt aus derselben Quelle stammen`}</a>{` und aufeinanderfolgende Kapitel einer Religion von Gott sind. In einer Welt, die um ein friedliches Zusammenleben verschiedener Völker kämpft, fordert Bahá’u’lláh die Menschheit auf, die Spaltung abzulehnen und eine `}<a parentName="p" {...{
        "href": "https://www.bahai.de/gemeinsames-handeln/gemeinsames-lernen/"
      }}>{`einheitliche Vision der Zukunft der Gesellschaft`}</a>{` anzunehmen.`}</p>
    <p>{`Die Bahá’í-Gemeinde Mannheim ist eine offene und junge Gemeinde und tragen mit ihren Mitmenschen aktiv zur Verbesserung der unmittelbaren Lebensverhältnisse hier in Mannheim und Umgebung, seien sie sozialer oder geistiger Natur, bei.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      